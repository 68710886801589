import React, { useState } from 'react';

import classNames from 'classnames';
import { CustomIcon } from 'components/Icon/CustomIcon';

import styles from './mbutton.module.css';

type Props = {
  children: React.ReactNode;
  onClick?: () => Promise<unknown> | void;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'text' | 'contained' | 'outlined';
  loading?: boolean;
  disabled?: boolean;
  className?: string;
};

export function MButton({
  onClick,
  children,
  type = 'button',
  disabled = false,
  variant = 'contained',
  className,
}: Props) {
  const [isLoading, setLoading] = useState(false);

  const onClickHandler = async () => {
    if (typeof onClick === 'function') {
      const callResult = onClick();
      if (callResult && typeof callResult.then === 'function') {
        setLoading(true);
        await callResult;
        setLoading(false);
      }
    }
  };
  const isDisabled = disabled || isLoading;

  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={onClickHandler}
      className={classNames(styles.button, styles[variant], className)}
    >
      {isLoading ? (
        <div className={styles.loaderIconWrapper}>
          <CustomIcon icon="loading" />
        </div>
      ) : (
        children
      )}
    </button>
  );
}
