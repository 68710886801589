import React, { FC, useEffect, useRef, useState } from 'react';

import { ReactComponent as TriangleSvg } from 'icons/triangle-hover.svg';

import styles from './hoverableSection.module.css';

type HoverableSectionProps = {
  header: React.ReactNode;
  offsetTop?: number;
} & PropsWithChildren;

export const HoverableSection: FC<HoverableSectionProps> = ({
  header,
  children,
  offsetTop = 0,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [containerHalfWidth, setContainerHalfWidth] = useState('0px');

  useEffect(() => {
    if (ref.current) {
      const containerWidth = ref.current.offsetWidth;
      setContainerHalfWidth(`${containerWidth / 2}px`);
    }
  }, []);

  return (
    <div ref={ref} className={styles.container}>
      <div>{header}</div>
      <div style={{ height: offsetTop }} className={styles.ghostHeightWhenOffset} />
      <div
        style={
          {
            '--child-offset-top': `${offsetTop}px`,
            '--parent-half-width': containerHalfWidth,
          } as React.CSSProperties
        }
        className={styles.child}
      >
        <div className={styles.triangleWrapper}>
          <div className={styles.triangle} />
        </div>
        {children}
      </div>
    </div>
  );
};
