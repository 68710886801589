import React from 'react';

import classNames from 'classnames';

import styles from './mText.module.css';

type Props = {
  children: React.ReactNode;
  level: 0 | 1 | 2 | 3 | 4 | 5;
  color?: 'white' | 'gray' | 'grayContent' | 'lightGray' | 'peach';
  className?: string;
};

export function MHeading({ children, level = 1, color = 'white', className }: Props) {
  const TagName = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <TagName className={classNames(className, styles[`h${level}`], styles[color])}>
      {children}
    </TagName>
  );
}
