import React, { FC } from 'react';

import { MText } from 'components/Typography/MText/MText';
import { HoverableSection } from 'components/organisms/HoverableSection';

import {
  exploreHeaderLinks,
  getInvolvedHeaderLinks,
  learnHeaderLinks,
  SECONDARY_HEADER_HOVER_OFFSET_TOP,
} from '../header.const';
import { LinkContentBox } from './LinkContentBox/LinkContentBox';

const linkList = [
  {
    id: 1,
    headerText: 'Learn',
    items: learnHeaderLinks,
    imgSrc: '/assets/header/learn.webp',
  },
  {
    id: 2,
    headerText: 'Explore',
    items: exploreHeaderLinks,
    imgSrc: '/assets/header/explore.webp',
  },
  {
    id: 3,
    headerText: 'Get Involved',
    items: getInvolvedHeaderLinks,
    imgSrc: '/assets/header/get-involved.webp',
  },
];
type LinkListProps = {
  addOffset?: boolean;
};

export const HeaderLinkList: FC<LinkListProps> = ({ addOffset = false }) => {
  return (
    <>
      {linkList.map((item) => (
        <React.Fragment key={item.id}>
          <HoverableSection
            offsetTop={addOffset ? SECONDARY_HEADER_HOVER_OFFSET_TOP : 0}
            header={<MText size="body">{item.headerText}</MText>}
          >
            <LinkContentBox items={item.items} imgSrc={item.imgSrc} />
          </HoverableSection>
        </React.Fragment>
      ))}
    </>
  );
};
