import { useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import { ToasterMessages } from 'components/Toaster/components/ToasterMessages';
import ToasterProvider from 'components/Toaster/toaster.provider';
import { Footer } from 'layout/Footer/Footer';
import { Header } from 'layout/Header/Header';
// providers
import { GrantProvider } from 'pages/Grants/grants.provider';
import { StableNavigateContextProvider } from 'providers/StableNavigation';
import { AppRoutes } from 'routes/AppRoutes';
import 'styles/theme/grid.module.css';
import ThemeProvider from 'styles/theme/theme.provider';

const newRoutes = ['/', '/testnet'];
const AppContent = () => {
  const { pathname } = useLocation();

  const renderOldLayout = useMemo(() => !newRoutes.includes(pathname), [pathname]);

  return (
    <>
      {renderOldLayout && <Header alternativeDesign ghostHeight />}

      <AppRoutes />
      <ToasterMessages />
      {renderOldLayout && <Footer />}
    </>
  );
};

// Add providers here, not at index.tsx
const App = () => {
  return (
    <Router>
      <StableNavigateContextProvider>
        <HelmetProvider>
          <ToasterProvider>
            <ThemeProvider>
              <GrantProvider>
                <AppContent />
              </GrantProvider>
            </ThemeProvider>
          </ToasterProvider>
        </HelmetProvider>
      </StableNavigateContextProvider>
    </Router>
  );
};

export default App;
