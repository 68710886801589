import { FC } from 'react';
import { Link } from 'react-router-dom';

import { NavHeaderItem } from 'components/NavigationDropDown/navigationDropDown.types';
import { MText } from 'components/Typography/MText/MText';

import styles from './linkContentBox.module.css';

type LinkContentBoxProps = {
  items: NavHeaderItem[];
  imgSrc: string;
};

export const LinkContentBox: FC<LinkContentBoxProps> = ({ items, imgSrc }) => {
  return (
    <div className={styles.box}>
      <div className={styles.navList}>
        {items.map((item) => (
          <LinkContentItem
            key={item.title}
            header={item.title}
            body={item.body}
            Icon={item.icon}
            link={item.link}
          />
        ))}
      </div>
      <div className={styles.imageBox}>
        <img src={imgSrc} alt="nav item bg" />
      </div>
    </div>
  );
};

type LinkContentItemProps = {
  Icon: IconSVG;
  header: string;
  body?: string;
  link: string;
};

export const LinkContentItem: FC<LinkContentItemProps> = ({ Icon, header, body, link }) => {
  return (
    <Link to={link}>
      <div className={styles.linkItem}>
        <div className={styles.linkItemIcon}>
          <Icon />{' '}
        </div>
        <div className={styles.linkIteContent}>
          <MText size="body" weight="bold" className={styles.linkIteContentHeader}>
            {header}
          </MText>
          {body && (
            <MText size="smallBody" className={styles.linkIteContentBody}>
              {body}
            </MText>
          )}
        </div>
      </div>
    </Link>
  );
};
