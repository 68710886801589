export const lockBodyScroll = (isLock: boolean) => {
  if (document && document.body) {
    const { body } = document;
    if (isLock) {
      body.style.overflowY = 'scroll';
      body.style.top = `-${window.pageYOffset}px`;
      body.style.position = 'fixed';
      body.style.width = '100%';
    } else {
      const scrollY = Math.abs(parseInt(body.style.top || '0', 10));
      body.style.overflow = '';
      body.style.position = '';
      body.style.top = '';
      body.style.width = '';
      scrollY && window.scrollTo(0, scrollY);
    }
  }
  return undefined;
};
