import { NavHeaderItem } from 'components/NavigationDropDown/navigationDropDown.types';
import { ReactComponent as AmbassadorSvg } from 'icons/ambassador.svg';
import { ReactComponent as CommunitySvg } from 'icons/community.svg';
import { ReactComponent as DollarLinkSvg } from 'icons/dollar-link.svg';
// learn
import { ReactComponent as MCoinSvg } from 'icons/m-coin.svg';
import { ReactComponent as MLetterSvg } from 'icons/m-letter.svg';
import { ReactComponent as ManCircleSvg } from 'icons/man-circle.svg';
import { ReactComponent as ManSuccessSvg } from 'icons/man-success.svg';
import { ReactComponent as OpenedWalletSvg } from 'icons/opened-wallet.svg';
import { ReactComponent as RwaSvg } from 'icons/rwa-icon.svg';
import { ReactComponent as SettingsSvg } from 'icons/settings-wrapped.svg';
import { ReactComponent as StackedCoinsSvg } from 'icons/stacked-coins.svg';
import { ReactComponent as TownHallSvg } from 'icons/town-hall.svg';
import { ReactComponent as WrappedCubeSvg } from 'icons/wrapped-cube.svg';

import { pathnames } from '../../routes/routes';

export const blueRecolorPages = [
  pathnames.VALIDATOR,
  pathnames.DELEGATOR_TAB,
  pathnames.VAILDATOR_TAB,
  pathnames.MEDIA_KIT,
  pathnames.COMMUNITY,
  pathnames.GRANTS,
  pathnames.FOUNDATION_DELEGATIONS,
] as const;

// COMMENT THE PATH TO HAVE THE THEME SWITCHER
export const pagesWithoutThemeButton = [
  pathnames.TESTNET,
  pathnames.VALIDATOR,
  pathnames.DELEGATOR_TAB,
  pathnames.VAILDATOR_TAB,
  pathnames.UTILITY_AND_ECONOMICS,
  pathnames.MEDIA_KIT,
  pathnames.COMMUNITY,
  pathnames.GRANTS,
  pathnames.GRANT_DETAILS,
  pathnames.FOUNDATION_DELEGATIONS,
  pathnames.HOME,
  pathnames.LITEPAPER,
  pathnames.RWA,
  pathnames.PROTOCOL_TREASURY,
  pathnames.LIQUIDITY_MINING,
  pathnames.ABOUT_US,
  pathnames.DOMAINS,
  pathnames.WALLET_TEASER,
] as const;

export const pagesWithoutStayTunedButton = [pathnames.HOME] as const;

export const litepaperNav = { title: 'Litepaper', link: pathnames.LITEPAPER };

export const learnHeaderLinks: NavHeaderItem[] = [
  {
    title: 'Validator',
    link: pathnames.VALIDATOR,
    body: 'Help run the Mavryk Network',
    icon: ManSuccessSvg,
  },
  {
    title: 'Delegator',
    link: pathnames.DELEGATOR_TAB,
    body: 'Help secure the Mavryk Network',
    icon: ManCircleSvg,
  },
  {
    title: 'Utility & Economics',
    link: pathnames.UTILITY_AND_ECONOMICS,
    body: 'All about the MVRK coin',
    icon: MCoinSvg,
  },
  {
    title: 'RWA',
    link: pathnames.RWA,
    body: 'Discover Real World Assets',
    icon: RwaSvg,
  },
];

export const exploreHeaderLinks: NavHeaderItem[] = [
  {
    title: 'Mavryk Domains',
    link: pathnames.DOMAINS,
    body: 'Checkout Mavryk Domains',
    icon: WrappedCubeSvg,
  },
  {
    title: 'Mavryk Wallet',
    link: pathnames.WALLET_TEASER,
    body: 'Your One-Stop Web3 Wallet',
    icon: OpenedWalletSvg,
  },
  {
    title: 'Protocol Treasury',
    link: pathnames.PROTOCOL_TREASURY,
    body: 'Explore On-Chain Funding',
    icon: TownHallSvg,
  },
  {
    title: 'Liquidity Mining',
    link: pathnames.LIQUIDITY_MINING,
    body: 'Explore Liquidity Mining',
    icon: StackedCoinsSvg,
  },
];
export const getInvolvedHeaderLinks: NavHeaderItem[] = [
  { title: 'About us', link: pathnames.ABOUT_US, body: 'What is Mavryk?', icon: MLetterSvg },
  {
    title: 'Community',
    link: pathnames.COMMUNITY,
    body: 'Explore Mavryk community ',
    icon: CommunitySvg,
  },
  {
    title: 'Pre-Launch Bounties',
    link: pathnames.GRANTS,
    body: 'Contribute to Building Mavryk',
    icon: DollarLinkSvg,
  },
  {
    title: 'Foundation Delegations',
    link: pathnames.FOUNDATION_DELEGATIONS,
    body: 'All about delegations',
    icon: SettingsSvg,
  },
  {
    title: 'Brand & Media Kit',
    link: pathnames.MEDIA_KIT,
    body: 'All about the brand',
    icon: AmbassadorSvg,
  },
];

export const SECONDARY_HEADER_Y_OFFSET = 200;
export const SECONDARY_HEADER_HOVER_OFFSET_TOP = 10;

// lottie
export const LOGO_ANIMATION_NAME = 'logo';
