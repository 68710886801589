import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import lottie, { AnimationItem } from 'lottie-web';

import { LottieParameters, useStopLottieAnimation } from './useStopLottieAnimation';

export type LottieHookOptions = Omit<LottieParameters, 'container'> & { animationData: unknown };

export type AnimationItemMethods = {
  [K in keyof AnimationItem]?: AnimationItem[K] extends (...args: any[]) => any
    ? Parameters<AnimationItem[K]>
    : never;
};

/**
 *
 * @param animationName the name of animation, should be static outside the component
 * @param lottieOptions defailt lottie options except  *container* property which is handled insidwe hook
 * @returns {lottieRefCallback, sectionRef} - lottie ref callback used for the actual animation,
 * sectionRef used to pause or play animation when it is in view
 */
export const useLottieAnimation = <G extends HTMLElement>(
  animationName: string,
  lottieOptions: Partial<LottieParameters>,
  methods?: Partial<AnimationItemMethods>
) => {
  const animationInstanceRef = useRef<AnimationItem | null>(null);
  const [element, setElement] = useState<G | null>(null);

  const lottieRefCallbak = useCallback((node: G) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  const internalLottieOptions: LottieParameters = useMemo(
    () => ({
      container: element!,
      ...lottieOptions,
    }),
    [element, lottieOptions]
  );

  const { ref } = useStopLottieAnimation<G>(animationName);

  useEffect(() => {
    const animation = lottie.loadAnimation(internalLottieOptions);
    animationInstanceRef.current = animation;

    if (methods) {
      // methods like stop, pley, setSpeed etc. are stored in prototype of animation instance
      // so we need to get them for our typeguard
      const animationPrototype = Object.getPrototypeOf(animation);
      const keys = Object.getOwnPropertyNames(animationPrototype) as (keyof AnimationItem)[];

      if (areAnimationMethods(methods, keys)) {
        Object.entries(methods).forEach(([key, value]) => {
          animation[key as keyof AnimationItem](...value);
        });
      }
    }

    return () => {
      animation.destroy(animationName);
      animationInstanceRef.current = null;
    };
  }, [internalLottieOptions, animationName, methods]);

  return { lottieRefCallbak, sectionRef: ref, animationInstanceRef };
};

// typeguard
function areAnimationMethods(
  value: unknown,
  keys: string[]
): value is Partial<AnimationItemMethods> {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  for (const key in value) {
    if (!keys.includes(key as keyof AnimationItem)) {
      return false; // Invalid key
    }

    const methodArgs = (value as Record<string, any>)[key];
    if (!Array.isArray(methodArgs)) {
      return false; // Values should be arrays (parameters)
    }
  }

  return true;
}
