import { Link } from 'react-router-dom';

import { NavHeaderItem } from 'components/NavigationDropDown/navigationDropDown.types';
import { MHeading } from 'components/Typography/MText/MHeading';
import { MText } from 'components/Typography/MText/MText';

import styles from '../mobileMenu.module.css';

export const BurgerSubHeadingItem = ({ item }: { item: NavHeaderItem }) => {
  return item ? (
    <Link key={item.title} to={item.link} className={styles.subHeadingItem}>
      <div className={styles.linkItemIcon}>
        <item.icon />
      </div>

      <div className={styles.subHeadingItemTextWrapper}>
        <MHeading level={5} className={styles.subHeadingItemHeader}>
          {item.title}
        </MHeading>
        {item.body && (
          <MText size="smallBody" className={styles.subHeadingItemBody}>
            {item.body}
          </MText>
        )}
      </div>
    </Link>
  ) : null;
};
