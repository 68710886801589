import React from 'react';

import styles from './mediaQueryBlocks.module.css';

type Props = {
  children: React.ReactNode;
};

export function MobileOnly(props: Props) {
  return <div className={styles.mobileOnly}>{props.children}</div>;
}

export function DesktopOnly(props: Props) {
  return <div className={styles.desktopOnly}>{props.children}</div>;
}
