import {
  MAVRYK_GITHUB_URL,
  MAVRYK_TELEGRAM_URL,
  MAVRYK_TWITTER_URL,
  MAVRYK_DISCORD_URL,
} from 'consts/url.const';

const defaultLink = 'https://mavryk.finance/';

export type FooterNavItem = {
  title: string;
  httpLink: string;
};

export type FooterNavigation = {
  [key: string]: FooterNavItem[];
};

// uncomment sections to test flexibility

export const footerNavigationData: FooterNavigation = {
  discover: [
    {
      title: 'Testnet',
      httpLink: `/testnet`,
    },
    {
      title: 'litepaper',
      httpLink: `/litepaper`,
    },
    {
      title: 'Contact Us',
      httpLink: 'mailto:info@mavryk.io?subject=Partner with Mavryk',
    },

    // {
    //   title: 'changelog',
    //   httpLink: defaultLink,
    // },
    // {
    //   title: 'tutorials',
    //   httpLink: defaultLink,
    // },
    // {
    //   title: 'install',
    //   httpLink: defaultLink,
    // },
    // {
    //   title: 'marketplace',
    //   httpLink: defaultLink,
    // },
  ],
  // intergrations: [
  //   {
  //     title: 'react',
  //     httpLink: defaultLink,
  //   },
  //   {
  //     title: 'vue',
  //     httpLink: defaultLink,
  //   },
  //   {
  //     title: 'threejs',
  //     httpLink: defaultLink,
  //   },
  //   {
  //     title: 'react three fiber',
  //     httpLink: defaultLink,
  //   },
  //   {
  //     title: 'webflow',
  //     httpLink: defaultLink,
  //   },
  //   {
  //     title: 'mapbox',
  //     httpLink: defaultLink,
  //   },
  // ],
  ecosystem: [
    {
      title: 'Maven Finance',
      httpLink: 'https://mavenfinance.io/',
    },
    {
      title: "Nexus",
      httpLink: "https://nexus.mavryk.org/",
    },
    {
      title: "Equiteez",
      httpLink: "https://equiteez.com/",
    }
  ],
  legal: [
    // {
    //   title: 'discord',
    //   httpLink: defaultLink,
    // },
    // {
    //   title: 'forum',
    //   httpLink: defaultLink,
    // },
    // {
    //   title: 'Cookies Policy',
    //   httpLink: '#',
    // },
    {
      title: 'Privacy Policy',
      httpLink:
        'https://docs.google.com/document/d/14Op7Oij6fpBDVmw88B1DpSoTElAQIbNrGzdw1kUaRF4/edit',
    },
    // {
    //   title: 'Terms of Use',
    //   httpLink: '#',
    // },
    // {
    //   title: 'youtube',
    //   httpLink: defaultLink,
    // },
    // {
    //   title: 'About',
    //   httpLink: defaultLink,
    // },
  ],
};

// uncomment sections to test flexibility
export const socialMediaData = [
  { name: 'twitter', link: MAVRYK_TWITTER_URL },
  { name: 'discord', link: MAVRYK_DISCORD_URL },
  { name: 'github', link: MAVRYK_GITHUB_URL },
  { name: 'telegram', link: MAVRYK_TELEGRAM_URL },
];
