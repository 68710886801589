import { useEffect, useRef } from 'react';

import lottie, { AnimationConfigWithData, AnimationConfigWithPath, RendererType } from 'lottie-web';

import useOnScreen from '../useOnScreen';

export type LottieParameters =
  | AnimationConfigWithPath<RendererType>
  | AnimationConfigWithData<RendererType>;

/**
 * 
 * @param animationName name of the lottie animation
 * @returns ref to the container ref and boolean value indicating if the parent container is in the view
 * 
 * @example
 *   const { ref: bannerRef, isElementInView } = useStopLottieAnimation('heroBanner');

  const lottieParams:
    | AnimationConfigWithPath<RendererType>
    | AnimationConfigWithData<RendererType> = useMemo(
    () => ({
      container: ref.current!,
      animationData: HeroAnimation,
      loop: isElementInView ? false : true,
      autoplay: true,
      renderer: 'canvas',
      name: 'heroBanner',
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [isElementInView]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (ref.current) {
      lottie.loadAnimation(lottieParams);
    }

    return () => {
      lottie.destroy('heroBanner');
      if (timeout) clearTimeout(timeout);
    };
  }, [lottieParams]);
 */
export const useStopLottieAnimation = <G extends HTMLElement>(animationName: string) => {
  const ref = useRef<G | null>(null);
  const isElementInView = useOnScreen<G>(ref);

  useEffect(() => {
    if (!isElementInView) {
      lottie.pause(animationName);
    } else {
      lottie.play(animationName);
    }
  }, [animationName, isElementInView]);

  return { ref, isElementInView };
};
