import { useRef } from 'react';

import classNames from 'classnames';
import { NavHeaderItem } from 'components/NavigationDropDown/navigationDropDown.types';
import { ReactComponent as MinusIcon } from 'icons/minus.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';

import styles from '../mobileMenu.module.css';
import { BurgerSubHeadingItem } from './BurgerSubHeadingItem';

type BurgerExpanderItemProps = {
  text: string;
  items: NavHeaderItem[];
  toggleMenuItem: (idx: number) => void;
  serialNumber: number;
  isOpen: boolean;
};

export const BurgerExpanderItem = ({
  text,
  items,
  toggleMenuItem,
  serialNumber,
  isOpen = false,
}: BurgerExpanderItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const toggleOpenFlag = () => {
    toggleMenuItem(serialNumber);
  };

  const handleItemClick = async () => {
    toggleOpenFlag();
  };

  return (
    <>
      <div
        ref={ref}
        className={classNames(styles.link, styles.expanderHeader, {
          [styles.active]: isOpen,
        })}
        onClick={handleItemClick}
      >
        {text}
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      <div className={styles.lineDivider} />
      <section
        className={classNames(styles.expanderItemList, {
          [styles.active]: isOpen,
        })}
      >
        {items.map((item) => (
          <BurgerSubHeadingItem key={item.title} item={item} />
        ))}
        <div className={styles.lineDivider} />
      </section>
    </>
  );
};
