import React from 'react';

import { Icon } from 'components/Icon/Icon';

import { socialMedias } from './socials.const';
import styles from './socials.module.css';

export const Socials = () => {
  return (
    <div className={styles.socialMedia}>
      {socialMedias.map((item) => (
        <a key={item.link} href={item.link} rel="noreferrer" target="_blank">
          <Icon id={item.name} />
        </a>
      ))}
    </div>
  );
};
